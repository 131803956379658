<template>
  <section class="register">
    <div>
      <div class="row">
        <!-- :class="{ 'active-button': index === activeButtoni2 }" -->
        <div
          :class="{'regiester-wrapper': logo1 == true,
                   'regiester-wrapper1': logo2 == true,
                   'regiester-wrapper2': logo3 == true,
                   'regiester-wrapper3': logo4 == true,

          }"
        >
          <div class="register-content">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <p style="color: white;font-size: 25px;margin-bottom: 0px;">
                {{ year }}
              </p>
              <p style="color: white;font-size: 60px;margin-bottom: 0px;">
                {{ hours }}
              </p>
              <p style="color: white;font-size: 35px;margin-bottom: 0px;">
                {{ days }}
              </p>
            </div>
            <div>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                style="font-size: 16px; width: 100%; color: #344054; font-weight: 500;display: flex;flex-direction: column;align-items: center;"
              >
                <div style="display: flex;flex-direction: column;align-items: center;">
                  <img
                    src="@/assets/images/avatarMale.jpg"
                    width="50px"
                    height="50px"
                    alt="test"
                    style="border-radius: 50%;margin-bottom: 0px;"
                  >
                  <b-form-input
                    id="input-1"
                    v-model="$v.login.email.$model"
                    style="height: 50px;background: transparent;border: 0px solid;color: #FFFFFF;justify-content: center;display: flex;"
                    :state="validateState('email')"
                    aria-describedby="input-1-live-feedback"
                    placeholder="Enter your email"
                    disabled
                  />
                  <!-- <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >{{ $t('RequiredField') }}</b-form-invalid-feedback> -->
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-1"
                style="font-size: 16px; margin-top: 5px; color: #344054; font-weight: 500;"
              >
                <div class="input-group mb-3">
                  <b-form-input
                    id="input-2"
                    v-model="$v.login.password.$model"
                    style="height: 50px;"
                    placeholder="Enter your password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control"
                    :state="validateState('password')"
                    aria-describedby="input-1-live-feedback"
                    @keyup.enter="onSubmit"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      style="border-radius:0 5px 5px 0px; height: 50px;"
                      @click="showPassword = !showPassword"
                    >
                      <span><i
                        class="fa"
                        :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                        aria-hidden="true"
                      />
                      </span>
                    </span>
                  </div>
                </div>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ $t('RequiredField') }}</b-form-invalid-feedback>
              </b-form-group>
              <div
                class="error"
                :style="isLogged == false && getIsLoggedIn == false ? 'justify-content: space-between' : 'justify-content: flex-end'"
              >
                <p
                  v-if="isLogged == false && getIsLoggedIn == false"
                  style="color:red"
                  class="test"
                >
                  {{ $t('Email_Or_Password_Is_Incorrect') }}
                </p>
              </div>
              <router-link to="/login">
                <p
                  style="color:white; text-decoration: underline;"
                  @click="logoutSession"
                >
                  Switch user
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ForgotPasswordModal />
    <ChangeFirstPasswordModal
      :login="login"
      @changeP="changePass"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    >
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required, email,
} from 'vuelidate/lib/validators'
import eventBus from '@/main'
import * as signalR from '@microsoft/signalr'
import { client, api } from '@/domainConfig'
import moment from 'moment'
import ForgotPasswordModal from './products/modals/ForgotPasswordModal.vue'
import ChangeFirstPasswordModal from './products/modals/ChangeFirstPasswordModal.vue'


const domain = api.stockManagementDomainNotification

// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
export default {
  components: {
    ForgotPasswordModal,
    ChangeFirstPasswordModal,
  },

  mixins: [validationMixin],
  data() {
    return {
      logo1: false,
      logo2: false,
      logo3: false,
      logo4: false,
      client: client.clientName,
      showPassword: false,
      year: moment().format('D MMMM, YYYY'),
      hours: moment().format('HH:mm'),
      days: moment().format('dddd'),
      show: true,
      isLogged: true,
      loading: false,
      dataFromSignup: null,
      login: {
        email: '',
        password: '',
      },
    }
  },
  validations: {

    login: {
      email: {
        required,
        email,
      },
      // password: {
      //   required, minLength: minLength(8), maxLength: maxLength(30), regex: regex('^(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$'),
      // },
      password: {
        required,
        // passwordRegex(value) {
        //   if (!passwordRegex.test(value)) {
        //     return false;
        //   }
        //   return true;
        // },
      },
    },
  },

  computed: {
    ...mapGetters(['getIsLoggedIn', 'getLoggedInUser']),
  },
  watch: {
    dataFromSignup(value) {
      this.login.email = value
    },
  },
  mounted() {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    this[`logo${randomNumber}`] = true;
  },
  created() {
    // Try to get data from localStorage on component creation
    const storedData = localStorage.getItem('signupData');
    if (storedData) {
      this.dataFromSignup = JSON.parse(storedData);
    }

    // Listen for the event on the event bus
    eventBus.$on('signup-data', (data) => {
      // Handle the emitted data
      this.dataFromSignup = data;

      // Save the data to localStorage
      localStorage.setItem('signupData', JSON.stringify(data));
    });
  },
  methods: {
    ...mapActions(['attemptLogin', 'changePasswordForUser', 'getUsersPagination']),

    validateState(name) {
      const { $dirty, $error } = this.$v.login[name];
      return $dirty ? !$error : null;
    },
    logoutSession() {
      localStorage.removeItem('signupData');
    },
    onSubmit() {
      this.$v.login.$touch();
      if (this.$v.login.$anyError) {
        return;
      }

      // eslint-disable-next-line no-new
      // const myPromise = new Promise(() => {
      this.attemptLogin({
        object: this.login,
        successCallback: () => {
          this.isLogged = true
          this.loading = true
          if (this.getLoggedInUser.isFirstLoginExecuted == false) {
            this.$bvModal.show('modal-change-first-password');
          } else {
            this.$router.push('/');
          }
        },
      })
      eventBus.$emit('signup-data', this.login.email);
      localStorage.setItem('signupData', JSON.stringify(this.login.email));
      // })
      this.login.password = ''
      // myPromise.then(
      //   () => {
      // console.log('token', this.getLoggedInUser.token)
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${domain}/NotificationUserHub`, {
          accessTokenFactory: () => {
            return this.getLoggedInUser.token;
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

      try {
        connection.start();
        console.log('SignalR Connected.');
      } catch (err) {
        // setTimeout(start, 7000);
      }
      // connection.start()
      // },
      // )
      setTimeout(() => {
        if (this.getIsLoggedIn == false) {
          this.isLogged = false
          // this.login.email = ''
          // this.login.password = ''
          setTimeout(() => { this.$v.$reset() }, 0)
          this.$nextTick(() => { this.$v.$reset() })
        }
      }, 1000)

      this.loading = !false
      setTimeout(() => {
        this.loading = !true
      }, 1000)
    },
    async changePass(obj) {
      await this.changePasswordForUser({
        object: obj,
        successCallback: () => {
          this.$router.push('/');
        },
      })
    },
    openModal() {
      this.$bvModal.show('modal-forgot-password');
    },
    showL() {
      this.show = true;
    },
    showR() {
      this.show = false;
    },
  },
}
</script>

<style scoped lang="scss">
.regiester-wrapper {
  display: flex;
  justify-content: center;
  background: url('../assets/images/Lockout1Darken.png') no-repeat center center;
  background-size: cover;
}
.regiester-wrapper1 {
  display: flex;
  justify-content: center;
  background: url('../assets/images/Lockout2Darken.png') no-repeat center center;
  background-size: cover;
}
.regiester-wrapper2 {
  display: flex;
  justify-content: center;
  background: url('../assets/images/Lockout3Darken.png') no-repeat center center;
  background-size: cover;
}.regiester-wrapper3 {
  display: flex;
  justify-content: center;
  background: url('../assets/images/Lockout4Darken.png') no-repeat center center;
  background-size: cover;
}
.fa{
  color: $base-color;
}
.error{
  display: flex;
  align-items: flex-end;
}
.register-content{
  margin-right: 0px;
  gap: 150px;
}
.row{
  background-image: url('../assets/images/Backgroundpattern.svg');
  height: 500px;
  background-size: contain;
}

.buttonSubmit{
  background: #FF274F;
  border-radius: 8px;
  height: 44px;
}
input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

      .is-valid{
        border-color: lightgray;
        box-shadow: none;
      }

@media screen and (max-width: 1400px) {
  .logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  img{
    width: 500px;
  }
}
@media screen and (max-width: 1190px) {
.container {
  width: 100%;
  margin: 0 !important;
  max-width: inherit !important;
  padding: 0;
  .row {
    margin-right: 0;
    .regiester-wrapper {
      flex-direction: column-reverse;
      padding: 0;
      align-items: center;
      padding-top: 60px;
      .register-content {
    margin-right: 0px;
    max-width: 500px;
    min-width: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    margin-bottom: 100px;
    .logo {
      img {
        margin-bottom: 0;
        // height: 350px;
      }
    }
      }
      .register-image {
        height: 300px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
   }
  }
}
}
</style>
