<template>
  <div class="lockout-page">
    <LockoutSession />
  </div>
</template>

<script>
import LockoutSession from '@/components/LockoutSession.vue'

export default {

  name: 'Lockout',
  components: {
    LockoutSession,
  },
  // data() {
  //   return {
  //     apiKeys: [],
  //   }
  // },
  computed: {
    shouldShowSidebar() {
      return this.$route.meta.sidebar !== false;
    },
  //   taskBoards() {
  //     return this.$store.state.articles
  //   },
  // },
  // mounted() {
  //   this.fetchApikeys()
  // },
  // methods: {
  //   async fetchApikeys() {
  //     this.loading = true
  //     // await this.$store.dispatch('articles/fetchReadApiKeys', this.apiKeys)
  //     this.loading = false
  //   },
  },
}
</script>

<style lang="scss" scoped>

</style>
